// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogdetail-js": () => import("./../../../src/pages/blogdetail.js" /* webpackChunkName: "component---src-pages-blogdetail-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-detail-js": () => import("./../../../src/pages/detail.js" /* webpackChunkName: "component---src-pages-detail-js" */),
  "component---src-pages-djangodevelopercourse-js": () => import("./../../../src/pages/djangodevelopercourse.js" /* webpackChunkName: "component---src-pages-djangodevelopercourse-js" */),
  "component---src-pages-fullstackcourse-js": () => import("./../../../src/pages/fullstackcourse.js" /* webpackChunkName: "component---src-pages-fullstackcourse-js" */),
  "component---src-pages-germanclasses-js": () => import("./../../../src/pages/germanclasses.js" /* webpackChunkName: "component---src-pages-germanclasses-js" */),
  "component---src-pages-howwedo-js": () => import("./../../../src/pages/howwedo.js" /* webpackChunkName: "component---src-pages-howwedo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-japaneseclasses-js": () => import("./../../../src/pages/japaneseclasses.js" /* webpackChunkName: "component---src-pages-japaneseclasses-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mobiledevelopment-js": () => import("./../../../src/pages/mobiledevelopment.js" /* webpackChunkName: "component---src-pages-mobiledevelopment-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-python-advance-basic-course-js": () => import("./../../../src/pages/pythonAdvanceBasicCourse.js" /* webpackChunkName: "component---src-pages-python-advance-basic-course-js" */),
  "component---src-pages-pythondevelopercourse-js": () => import("./../../../src/pages/pythondevelopercourse.js" /* webpackChunkName: "component---src-pages-pythondevelopercourse-js" */),
  "component---src-pages-rateus-js": () => import("./../../../src/pages/rateus.js" /* webpackChunkName: "component---src-pages-rateus-js" */),
  "component---src-pages-reactdevelopercourse-js": () => import("./../../../src/pages/reactdevelopercourse.js" /* webpackChunkName: "component---src-pages-reactdevelopercourse-js" */),
  "component---src-pages-refundpolicy-js": () => import("./../../../src/pages/refundpolicy.js" /* webpackChunkName: "component---src-pages-refundpolicy-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-smo-js": () => import("./../../../src/pages/smo.js" /* webpackChunkName: "component---src-pages-smo-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */),
  "component---src-pages-webdevelopment-js": () => import("./../../../src/pages/webdevelopment.js" /* webpackChunkName: "component---src-pages-webdevelopment-js" */),
  "component---src-pages-whatwedo-js": () => import("./../../../src/pages/whatwedo.js" /* webpackChunkName: "component---src-pages-whatwedo-js" */)
}

